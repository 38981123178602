import React, { Component } from "react";
import "./AdditionalServiceButton.css";

const SubmitButton = ({ icon, text, link }) => {
  const handleClick = () => {
    if (link) {
      window.open(link, "_blank");
    } else {
      alert("No link provided.");
    }
  };
  return (
    <button className="submit-button" onClick={handleClick}>
      {icon && (
        <span
          style={{
            marginRight: "5px",
            display: "flex",
            alignItems: "center",
            height: "100%",
          }}
        >
          <span
            style={{
              height: "16px",
              width: "16px",
              display: "inline-block",
            }}
          >
            {icon}
          </span>
        </span>
      )}{" "}
      {text}
    </button>
  );
};

export default SubmitButton;
