/* eslint-disable default-case */
/* global google */

import React, { Component } from "react";
import moment from "moment";
import _ from "lodash";
import "moment/min/locales";
import geolocationService from "../../services/geolocationService";
import menuImg from "../assets/menu.png";
import plusImg from "../assets/Plus.png";
import swapImg from "../assets/swap_icon.svg";
import googleImg from "../assets/powered_by_google_on_white.png";
import { isMobile } from "react-device-detect";
import { ReactComponent as Close } from "../assets/round-close-24px.svg";
import { ReactComponent as Location } from "../assets/round-my_location-24px.svg";
import { ReactComponent as LocationOn } from "../assets/round-location_filled-24px.svg";
import styles from "./EditJourney.module.scss";
import AutoComplete from "../common/Autocomplete";
import Button from "../common/Button";
import OptionsModal from "./OptionsModal";
import MarkerModal from "../MarkerModal/MarkerModal";
import { defaultFilters } from "../../domain/constants/filters";
import { FormattedMessage } from "react-intl";
import { ThemeContext } from "../../Context/ThemeContext";
import DirectionsModal from "../DirectionsModal/DirectionsModal";
import safeLocalStorageService from "../../services/useSafeLocalStorage";
import VehicleDisplay from "../common/VehicleDisplay";
import Header from "../common/Header";

class EditJourney extends Component {
  constructor(props) {
    super(props);

    this.startLocationInput = React.createRef();
    this.viaLocation1Input = React.createRef();
    this.viaLocation2Input = React.createRef();
    this.viaLocation3Input = React.createRef();
    this.endLocationInput = React.createRef();

    this.state = {
      showSuggestion: false,
      searchOperationsFinished: true,
      activeLocationTarget: "startLocation",
      activeTimeTarget: "departureTime",
      activeReturnTimeTarget: "departureTime",
      enableStartLocationUpdates: false,
      showOptionsModal: false,
      time: moment.parseZone().locale(this.props.lang),
      returnTime: null,
      startLocation: { eventId: "", id: "", description: "", groupName: "" },
      viaLocation1: { eventId: "", id: "", description: "", groupName: "" },
      viaLocation2: { eventId: "", id: "", description: "", groupName: "" },
      viaLocation3: { eventId: "", id: "", description: "", groupName: "" },
      endLocation: { eventId: "", id: "", description: "", groupName: "" },
      disableGoogleSuggestionList: this.props.disableGoogleSuggestionList,
      itineraryCount: 0,
      journeyPlan: {
        departureTime: props.departureTime,
        arrivalTime: props.arrivalTime,
        returnDepartureTime: props.returnDepartureTime,
        returnArrivalTime: props.returnArrivalTime,
      },
      ...defaultFilters,
      filterBus: this.props.publicTransportationOptions.filterBus,
      filterTrain: this.props.publicTransportationOptions.filterTrain,
      filterTram: this.props.publicTransportationOptions.filterTram,
      scheduledArrivalEvent: false,
      scheduledDepartureEvent: false,
      lastLat: null,
      lang: this.props.lang,
      lastLong: null,
      width: window.innerWidth,
      timeUpdated: false,
      isGoogleSuggestionActive: false,
      predictions: [],
      showSpecificNameFromSuggestionActive:
        this.props.showSpecificNameFromSuggestionActive,
      editProfile: false,
      isButtonHovered: false,
      shouldUpdateJourneys: false,
      shouldUpdateReturnJourneys: false,
    };
  }

  static contextType = ThemeContext;

  _enableGeolocation = (isEnabled) => {
    if (!isEnabled) {
      geolocationService.disableWatchPosition();
      return;
    }

    if (!geolocationService.getCurrentPosition()) {
      geolocationService.fetchCurrentPosition();
    }

    geolocationService.enableWatchPosition(
      (position) => {
        const mapRegion = { latitude: position.latitude };
        this.setState({
          mapRegion,
          lastLat: mapRegion.latitude,
          lastLong: mapRegion.longitude,
        });
      },
      (error) => console.log(error),
      { enableHighAccuracy: true }
    );
  };

  _setFilters = () => {
    const filters =
      this.props.showTravelPlan || !this.props.profilefilters
        ? this._getFiltersFromState()
        : this._getFiltersFromProps();

    this.setState(filters);
  };

  _getFiltersFromProps = () => {
    return {
      filterAdults: this.props.profilefilters.filterAdults,
      filterChildren: this.props.profilefilters.filterChildren,
      filterRemainingRange: this.props.profilefilters.filterRemainingRange,
      filterMaximumRange: this.props.profilefilters.filterMaximumRange,
      filterChargingTime: this.props.profilefilters.filterChargingTime,
      filterChademo: this.props.profilefilters.filterChademo,
      filterComboCCS: this.props.profilefilters.filterComboCCS,
      filterRapidAC43: this.props.profilefilters.filterRapidAC43,
      filterBus: this.props.publicTransportationOptions.filterBus,
      filterTrain: this.props.publicTransportationOptions.filterTrain,
      filterTram: this.props.publicTransportationOptions.filterTram,
      filterWheelchair: this.props.profilefilters.filterWheelchair,
      filterGuideDog: this.props.profilefilters.filterGuideDog,
      filterLuggage: this.props.profilefilters.filterLuggage,
      filterBicycle: this.props.profilefilters.filterBicycle,
    };
  };

  _getFiltersFromState = () => {
    return {
      filterAdults: this.state.filterAdults,
      filterChildren: this.state.filterChildren,
      filterRemainingRange: this.state.filterRemainingRange,
      filterMaximumRange: this.state.filterMaximumRange,
      filterChargingTime: this.state.filterChargingTime,
      filterChademo: this.state.filterChademo,
      filterComboCCS: this.state.filterComboCCS,
      filterRapidAC43: this.state.filterRapidAC43,
      filterWheelchair: this.state.filterWheelchair,
      filterGuideDog: this.state.filterGuideDog,
      filterLuggage: this.state.filterLuggage,
      filterBicycle: this.state.filterBicycle,
    };
  };
  _getPublicOptionsFromState = () => {
    return {
      filterBus: this.state.filterBus,
      filterTrain: this.state.filterTrain,
      filterTram: this.state.filterTram,
    };
  };

  _processLocationChange = (nextProps) => {
    const {
      defaultSuggestionActive,
      defaultFrom,
      defaultTo,
      destinations,
      events,
    } = nextProps;
    const defaultEvent = this.props.activeEvent;

    if (defaultEvent === undefined || defaultEvent.id === "") return;

    let locationData;
    const { startLocation, endLocation } = this.state;

    if (
      (nextProps.startLocation && startLocation !== nextProps.startLocation) ||
      (nextProps.endLocation && endLocation !== nextProps.endLocation)
    ) {
      this.setState({
        startLocation: nextProps.startLocation,
        endLocation: nextProps.endLocation,
      });
      return;
    }
    if (defaultSuggestionActive) {
      if (defaultTo !== "None") {
        if (defaultTo === "CurrentLocation") {
          if (endLocation.description === "") {
            this.setState({
              activeLocationTarget: "endLocation",
              startLocation: "",
            });
            this.arriveEventStart(defaultEvent);
            this.useCurrentLocation(this, "endLocation");
            return;
          }
          if (
            endLocation.description !== "" &&
            startLocation.description === ""
          ) {
            this.setState({ activeLocationTarget: "startLocation" });
            this.useCurrentLocation(this, "startLocation");
            return;
          }
          this.useCurrentLocation(this, "endLocation");
          return;
        } else {
          // if destination id
          const toDestination = destinations.find((el) => el.id === defaultTo);

          if (toDestination === undefined) {
            locationData = {
              eventId: destinations[0].relatedId,
              description: destinations[0].name,
              latitude: destinations[0].location.latitude,
              longitude: destinations[0].location.longitude,
            };
            this.arriveEventStart(defaultEvent);
          } else {
            //check to see if event is in the past
            //var nextDest = this.findNextEvent(toDestination);

            locationData = {
              eventId: toDestination.relatedId,
              description: toDestination.name,
              latitude: toDestination.location.latitude,
              longitude: toDestination.location.longitude,
            };
            this.arriveEventStart(
              events.find((ev) => ev.id === toDestination.relatedId)
            );
          }

          this.setState({
            activeLocationTarget: "endLocation",
            endLocation: locationData ?? endLocation,
          });
          return;
        }
      } else if (defaultFrom !== "None") {
        if (defaultFrom === "CurrentLocation") {
          if (startLocation.description === "") {
            this.setState({
              activeLocationTarget: "startLocation",
              endLocation: "",
            });
            this.departEventEnd(defaultEvent);
            this.useCurrentLocation(this, "startLocation");
            return;
          }
          if (
            startLocation.description !== "" &&
            endLocation.description === ""
          ) {
            this.setState({ activeLocationTarget: "endLocation" });
            this.useCurrentLocation(this, "endLocation");
            return;
          }
          this.useCurrentLocation(this, "startLocation");
          return;
        } else {
          const fromDestination = destinations.find(
            (el) => el.id === defaultFrom
          );

          locationData = {
            eventId: fromDestination.relatedId,
            description: fromDestination.name,
            latitude: fromDestination.location.latitude,
            longitude: fromDestination.location.longitude,
            code: fromDestination?.shortName,
          };

          this.departEventEnd(
            events.find((ev) => ev.id === fromDestination.relatedId)
          );
          this.setState({
            activeLocationTarget: "startLocation",
            startLocation: locationData,
          });
          return;
        }
      }
    } else {
      this.arriveEventStart(defaultEvent);

      locationData = {
        eventId: destinations[0].relatedId,
        description: destinations[0].name,
        latitude: destinations[0].location.latitude,
        longitude: destinations[0].location.longitude,
        code: destinations[0]?.shortName,
      };

      if (defaultEvent.startTime && defaultEvent.endTime) {
        this.setState({
          activeTimeTarget: "arrivalTime",
          activeReturnTimeTarget: "departureTime",
          activeLocationTarget: "endLocation",
          endLocation: locationData ?? endLocation,
        });
      } else {
        this.setState({
          activeTimeTarget: "departureTime",
          activeReturnTimeTarget: "departureTime",
          activeLocationTarget: "endLocation",
          endLocation: locationData ?? endLocation,
        });
      }
      this._processFiltersPropChange(nextProps);
    }
  };

  _processFiltersPropChange = (nextProps) => {
    if (!nextProps.filters) return;

    const time = nextProps.filters.departureTime
      ? moment.parseZone(nextProps.filters.departureTime)
      : moment.parseZone(nextProps.filters.arrivalTime);

    var returnTime;
    if (nextProps?.filters?.returnDepartureTime) {
      returnTime = moment.parseZone(nextProps.filters.returnDepartureTime);
    } else if (nextProps?.filters?.returnArrivalTime) {
      returnTime = moment.parseZone(nextProps.filters.returnArrivalTime);
    } else if (this.props.activeEvent) {
      returnTime = moment.parseZone(this.props.activeEvent.endTime);
    } else {
      returnTime = moment.parseZone().locale(this.props.lang);
    }

    const {
      arrivalTime,
      departureTime,
      returnArrivalTime,
      returnDepartureTime,
      endLocation,
      startLocation,
    } = nextProps.filters;

    const { filterAdults, filterChildren, filterWheelchair } =
      nextProps.filters;
    const { filterRemainingRange, filterMaximumRange, filterChargingTime } =
      nextProps.filters;
    const { filterChademo, filterComboCCS, filterRapidAC43 } =
      nextProps.filters;
    const filterBus = nextProps.publicTransportationOptions
      ? nextProps.publicTransportationOptions.filterBus
      : false;
    const filterTram = nextProps.publicTransportationOptions
      ? nextProps.publicTransportationOptions.filterTram
      : false;
    const filterTrain = nextProps.publicTransportationOptions
      ? nextProps.publicTransportationOptions.filterTrain
      : false;
    const { filterLuggage, filterGuideDog, filterBicycle } = nextProps.filters;
    var viaLocations = nextProps.filters.viaLocations;
    if (!viaLocations) {
      viaLocations = [];
    }

    const journeyPlan = {
      departureTime: departureTime,
      arrivalTime: arrivalTime,
      returnDepartureTime: returnDepartureTime
        ? returnDepartureTime
        : this.state.journeyPlan?.returnDepartureTime,
      returnArrivalTime: returnArrivalTime
        ? returnArrivalTime
        : this.state.journeyPlan?.returnArrivalTime,
    };

    const returnTargetTime = returnArrivalTime
      ? "arrivalTime"
      : "departureTime";

    const locations = [startLocation, ...viaLocations, endLocation];

    this.setState(
      {
        startLocation: locations[0] || {},
        endLocation: locations[1] || {},
        viaLocation1: locations[2] || {},
        viaLocation2: locations[3] || {},
        viaLocation3: locations[4] || {},
        filterAdults,
        filterChildren,
        filterRemainingRange,
        filterMaximumRange,
        filterChargingTime,
        filterChademo,
        filterComboCCS,
        filterRapidAC43,
        filterWheelchair,
        filterLuggage,
        filterGuideDog,
        filterBicycle,
        filterBus,
        filterTrain,
        filterTram,
        time,
        returnTime,
        journeyPlan,
        activeReturnTimeTarget: returnTargetTime,
      },
      () => {
        if (
          this.props.showTravelPlan &&
          safeLocalStorageService.getItem("itineraryInputControl") === null
        ) {
          safeLocalStorageService.setItem("itineraryInputControl", true);
          this.showTravelPlanItineraryInputControl();
        }
      }
    );
  };

  _processDestinationElementChange = (nextProps) => {
    const { activeLocationTarget } = this.state;

    // We're only interested in startLocation and endLocation changes.
    if (
      !(
        activeLocationTarget === "startLocation" ||
        activeLocationTarget === "endLocation"
      )
    )
      return;

    // if not clicked on from to fields, do not fill when clicked a marker on the map
    if (!this.state.showSuggestion) return;

    if (!nextProps?.destinationElement?.location) return;

    const location = {
      description: nextProps.destinationElement.name,
      eventId: nextProps.destinationElement.relatedId,
      latitude: nextProps.destinationElement.location.latitude,
      longitude: nextProps.destinationElement.location.longitude,
    };

    this.setState({ [activeLocationTarget]: location }, () => {
      this.props.setLocationDropDownOpen(
        () => true,
        () => {
          this.updateResults();
        }
      );
    });
  };

  _processJourneyPlanTimeChange = (nextProps) => {
    let { journeyPlan } = nextProps;

    const arrivalTime = nextProps.arrivalTime ?? nextProps.filters.arrivalTime;
    const departureTime =
      nextProps.departureTime ?? nextProps.filters.departureTime;

    journeyPlan = journeyPlan ? journeyPlan : {};

    if (arrivalTime != null) {
      journeyPlan.arrivalTime = moment.parseZone(arrivalTime).format();
    }

    if (departureTime != null) {
      journeyPlan.departureTime = moment.parseZone(departureTime).format();
    }

    if (journeyPlan.arrivalTime != null) {
      this._setDateTime(journeyPlan.arrivalTime);
      if (nextProps.showTravelPlan) {
        this.setState({ activeTimeTarget: "arrivalTime" });
      }
    } else if (journeyPlan.departureTime != null) {
      this._setDateTime(journeyPlan.departureTime);
      if (nextProps.showTravelPlan) {
        this.setState({ activeTimeTarget: "departureTime" });
      }
    }

    this.setState({ journeyPlan });

    this._processFiltersPropChange(nextProps);
  };

  _setDateTime = (dateTime) => {
    if (typeof dateTime !== "string") return;

    const date = moment.parseZone(dateTime);
    const updatedTime = {
      date: date.date(),
      month: date.month(),
      year: date.year(),
      hour: date.get("hours"),
      minutes: date.get("minutes"),
    };

    this.setState({ time: updatedTime });
  };

  componentDidMount = () => {
    this.props.handleReturnTimeChange(this.state.returnTime);
    this._setFilters();
  };

  componentDidUpdate = (prevProps, prevState) => {
    // Trigger return journey!
    if (
      this.props.showReturn != prevProps.showReturn &&
      this.props.showReturn &&
      !this.props.hasReturnJourneys
    ) {
      var journeyPlan = JSON.parse(JSON.stringify(this.state.journeyPlan));

      const {
        startLocation,
        endLocation,
        viaLocation1,
        viaLocation2,
        viaLocation3,
      } = this.state;

      const orderedLocations = [
        startLocation,
        endLocation,
        viaLocation1,
        viaLocation2,
        viaLocation3,
      ].filter((location) => !!location?.description);

      journeyPlan.startLocation = orderedLocations[0];
      journeyPlan.endLocation = orderedLocations[orderedLocations.length - 1];
      journeyPlan.viaLocations = orderedLocations.slice(
        1,
        orderedLocations.length - 1
      );

      journeyPlan.viaLocations.reverse();

      const filters = this._getFiltersFromState();
      const publicTransportationOptions = this._getPublicOptionsFromState();

      this.props.postReturnJourneyOptions(
        journeyPlan,
        filters,
        this.state.timeUpdated,
        publicTransportationOptions
      );

      this.setState({ shouldUpdateReturnJourneys: false });
    }

    if (
      this.props.selectedReturnDateTime !== prevProps.selectedReturnDateTime
    ) {
      this.setState({ returnTime: this.props.selectedReturnDateTime });
    }

    if (
      this.state.shouldUpdateReturnJourneys !==
        prevState.shouldUpdateReturnJourneys &&
      this.state.shouldUpdateReturnJourneys
    ) {
      this.props.clearReturnJourneys();
    }

    if (
      prevProps.departureTime !== this.props.departureTime ||
      prevProps.arrivalTime !== this.props.arrivalTime ||
      prevProps.returnDepartureTime !== this.props.returnDepartureTime ||
      prevProps.returnArrivalTime !== this.props.returnArrivalTime
    ) {
      const journeyPlan = {
        departureTime: this.props.departureTime,
        arrivalTime: this.props.arrivalTime,
        returnDepartureTime: this.props.returnDepartureTime,
        returnArrivalTime: this.props.returnArrivalTime,
      };
      this.setState({ journeyPlan });
      this._processJourneyPlanTimeChange(this.props);
    }

    if (this.props.initOperations !== prevProps.initOperations) {
      this._processLocationChange(this.props);
    }

    if (prevProps.complete !== this.props.complete) {
      if (this.props.complete && this.props.showTravelPlan) {
        this._processJourneyPlanTimeChange(this.props);
      }
    }

    if (
      prevProps.disableGoogleSuggestionList !==
      this.props.disableGoogleSuggestionList
    ) {
      this.setState({
        disableGoogleSuggestionList: this.props.disableGoogleSuggestionList,
      });
    }

    if (
      this.props.destinationElement !== prevProps.destinationElement ||
      this.props.destinationChangeStamp !== prevProps.destinationChangeStamp
    ) {
      this._processDestinationElementChange(this.props);
    }

    if (prevProps.profilefilters !== this.props.profilefilters) {
      this._setFilters();
    }

    if (prevProps.lang !== this.props.lang) {
      this.setState({
        time: moment.parseZone().locale(this.props.lang),
      });
    }

    if (
      !_.isEqual(
        this.props.publicTransportationOptions,
        prevProps.publicTransportationOptions
      )
    ) {
      const filterBus = this.props.publicTransportationOptions
        ? this.props.publicTransportationOptions.filterBus
        : false;
      const filterTram = this.props.publicTransportationOptions
        ? this.props.publicTransportationOptions.filterTram
        : false;
      const filterTrain = this.props.publicTransportationOptions
        ? this.props.publicTransportationOptions.filterTrain
        : false;

      this.setState({
        filterBus,
        filterTrain,
        filterTram,
      });
    }
  };

  componentWillUnmount = () => {
    this._enableGeolocation(false);
  };

  showTravelPlanItineraryInputControl = () => {
    if (!this.props.showTravelPlan) return;

    const { viaLocation1, viaLocation2, viaLocation3 } = this.state;
    if (viaLocation1 && viaLocation1.description) this.itineraryChange(1);
    if (viaLocation2 && viaLocation2.description) this.itineraryChange(2);
    if (viaLocation3 && viaLocation3.description) this.itineraryChange(3);
  };

  getEventLocation = (id, name, destination) => {
    this.props.resetViewportMarker();
    this.props.setViewport(
      destination.location.latitude,
      destination.location.longitude,
      14
    );

    this.props.setAlertsButton(true);
    let activeEvent;

    this.props.events.forEach((element) => {
      if (element.id === id) activeEvent = element;
    });

    const { activeLocationTarget } = this.state;

    if (activeLocationTarget === "startLocation")
      this.departEventEnd(activeEvent);
    else if (activeLocationTarget === "endLocation")
      this.arriveEventStart(activeEvent);

    this.setState(
      {
        [activeLocationTarget]: {
          eventId: id,
          description: name,
          latitude: destination.location.latitude,
          longitude: destination.location.longitude,
          id: destination.location.id,
          code: destination?.shortName,
          groupName: destination.location.groupName,
        },
        showSuggestion: false,
        shouldUpdateJourneys: true,
        shouldUpdateReturnJourneys: true,
      },
      () => {
        if (
          this.state.itineraryCount > 0 ||
          (this.state.startLocation.description &&
            activeLocationTarget === "endLocation") ||
          (this.state.endLocation.description &&
            activeLocationTarget === "startLocation")
        ) {
          this.updateResults();
        } else {
          this.props.toggleMarker(destination, this.props.events);
        }
      }
    );
  };

  setPrediction = (prediction) => {
    if (prediction.isCoordinates) {
      this.setPredictionState(
        prediction.latitude,
        prediction.longitude,
        prediction.title
      );
    } else if (
      this.props.suggestionSource !== "google" ||
      prediction.customPlace
    ) {
      this.setPredictionState(
        prediction.latitude,
        prediction.longitude,
        prediction.title,
        prediction.id,
        prediction.groupName
      );
    } else if (this.props.suggestionSource === "google") {
      const geocoder = new google.maps.Geocoder();
      geocoder.geocode({ placeId: prediction.placeId }, (responses, status) => {
        if (status !== "OK") return;
        const response = responses[0];
        this.setPredictionState(
          response.geometry.location.lat(),
          response.geometry.location.lng(),
          prediction.title,
          prediction.id,
          prediction.groupName
        );
      });
    }
  };

  setPredictionState = (lat, long, description, id, groupName) => {
    this.props.setViewportAndMarker(
      lat,
      long,
      // TODO: Maybe find calculate a more appropriate value somehow.
      14 // Zoom enough to see the buildings on the map.
    );

    this.setState(
      {
        [this.state.activeLocationTarget]: {
          description: description,
          latitude: lat,
          longitude: long,
          id: id,
          groupName: groupName,
        },
        showSuggestion: false,
        shouldUpdateJourneys: true,
      },
      () => {
        this.updateResults();
      }
    );
  };

  departEventEnd = (event, swap) => {
    let journeyPlan = { ...this.state.journeyPlan };
    let activeTimeTarget = "departureTime";

    if (event && event.endTime) {
      const parsedTime = moment
        .parseZone(event.endTime)
        .locale(this.props.lang);
      journeyPlan.departureTime = moment.parseZone(event.endTime).format();
      journeyPlan.arrivalTime = null;

      this.setState(
        {
          //timeUpdated: true,
          time: parsedTime,
          activeTimeTarget,
          journeyPlan,
          scheduledDepartureEvent: !swap ? true : false,
        },
        () =>
          console.log("await for setting the states - do not remove this log")
      );
    } else if (
      !this.state.timeUpdated &&
      this.state.journeyPlan.arrivalTime == null &&
      this.state.journeyPlan.departureTime == null &&
      !this.state.scheduledArrivalEvent
    ) {
      this.departNow("departure");
    }
  };

  arriveEventStart = (event, swap) => {
    if (event.startTime) {
      const time = moment.parseZone(event.startTime).locale(this.props.lang);
      var returnTime = null;
      if (event.endTime) {
        returnTime = moment.parseZone(event.endTime).locale(this.props.lang);
        this.props.setIsReturn(true);
      }

      this.setState((prevState) => ({
        timeUpdated: true,
        time,
        returnTime,
        activeTimeTarget: "arrivalTime",
        activeReturnTimeTarget: "departureTime",
        journeyPlan: {
          ...prevState.journeyPlan,
          departureTime: null,
          arrivalTime:
            moment.parseZone(event.startTime) <
            moment.parseZone().locale(this.props.lang)
              ? null
              : moment.parseZone(event.startTime).format(), //if the event has already started
          returnDepartureTime: moment.parseZone(event.endTime).format(),
        },
        scheduledArrivalEvent: !swap ? true : false,
      }));
    } else if (
      !this.state.timeUpdated &&
      this.state.journeyPlan.arrivalTime == null &&
      this.state.journeyPlan.departureTime == null &&
      !this.state.scheduledDepartureEvent
    ) {
      this.departNow("arrival");
    }
  };

  isDateInPast = (date) => {
    var now = Date.now();
    if (date < now) {
      return true;
    } else {
      return false;
    }
  };

  findNextEvent = (destinationGroup) => {
    var event = this.props.events.find(
      (e) => e.id === destinationGroup.relatedId
    );
    if (event !== null) {
      var ordered = this.props.events.sort(
        (a, b) => new Date(b.startTime) - new Date(a.startTime)
      );
      var index = ordered.findIndex((x) => x.startTime === event.startTime);

      //we work backwards because sometimes the first event doesnt have a start time.
      for (var i = index; i > 0; i--) {
        if (i - 1 >= 0) {
          var checkDate = this.isDateInPast(ordered[i - 1]);
          if (checkDate) {
            return ordered[i - 1];
          }
        }
      }
      return event;
    }
  };

  departNow = (source) => {
    const { journeyPlan, scheduledArrivalEvent, scheduledDepartureEvent } =
      this.state;

    const updatedJourneyPlan = { ...journeyPlan };

    if (source === "arrival" && !scheduledDepartureEvent) {
      updatedJourneyPlan.arrivalTime = null;
      this.setState({
        activeTimeTarget: "departureTime",
        journeyPlan: updatedJourneyPlan,
        scheduledArrivalEvent: false,
        time: moment.parseZone().locale(this.props.lang),
      });
    } else if (source === "departure" && !scheduledArrivalEvent) {
      updatedJourneyPlan.arrivalTime = null;
      this.setState({
        activeTimeTarget: "departureTime",
        journeyPlan: updatedJourneyPlan,
        scheduledDepartureEvent: false,
        time: moment.parseZone().locale(this.props.lang),
      });
    }
  };

  setAutoCompleteValue = (input, value) => {
    const inputId = `${input}Input`;
    value = value === undefined || value === "undefined" ? "" : value;

    if (this[inputId] && this[inputId].current) {
      this[inputId].current.autocompleteInput.current.value = value;
      this[inputId].current.autocompleteInput.current.placeholder =
        "Enter location";
    }

    this.setState({ [input]: value });
  };

  handleLocationInputFocus = (evt) => {
    // Reset input value.
    this.setAutoCompleteValue(evt.target.name, "");

    this.props.setTerminateLegAnimation(true);
  };

  handleLocationInputBlur = (evt) => {};

  handleLocationInputClick = (evt) => {
    const targetName = evt.target.name;
    const inputId = `${targetName}Input`;

    if (!this.props.locationDropDownOpen) {
      this.toggleClass();
    }

    // Reset input value.
    this.setAutoCompleteValue(targetName, "");

    // Compile newState data.
    const { journeyPlan } = this.state;

    const newState = {
      activeLocationTarget: targetName,
      showSuggestion: true,
      searchOperationsFinished: false,
      scheduledArrivalEvent: false,
      journeyPlan: { ...journeyPlan },
    };

    newState[inputId] = { description: "" };

    if (!this.state.timeUpdated) {
      if (inputId === "startLocationInput") {
        newState.journeyPlan.departureTime = null;
        newState.scheduledDepartureEvent = false;
      } else if (inputId === "endLocationInput") {
        newState.journeyPlan.arrivalTime = null;
        newState.scheduledArrivalEvent = false;
      } else {
        newState.journeyPlan.arrivalTime = null;
        newState.scheduledArrivalEvent = false;
      }
    }

    //Switch to travelling now!
    if (!this.state.timeUpdated) {
      if (
        (this.state.scheduledArrivalEvent === false &&
          inputId === "startLocationInput") ||
        (this.state.scheduledDepartureEvent === false &&
          inputId === "endLocationInput")
      ) {
        newState.time = moment.parseZone().locale(this.props.lang);
      }
    }
    // Update.
    this.setState(newState, () => {
      // Return to initial map if both to and from fields are empty
      if (this.state.startLocation === "" && this.state.endLocation === "") {
        this.props.setInitialMap();
        return;
      }
    });
  };

  /**
   *
   */
  handleSwapClick = () => {
    const endLocation =
      this.state.startLocation !== ""
        ? this.state.startLocation
        : { description: "" };
    const startLocation =
      this.state.endLocation !== ""
        ? this.state.endLocation
        : { description: "" };
    const { viaLocation1, viaLocation2, viaLocation3 } = this.state;

    // Swap location values.
    this.startLocationInput.current.autocompleteInput.current.value =
      startLocation.description;
    this.endLocationInput.current.autocompleteInput.current.value =
      endLocation.description;
    if (this.state.itineraryCount === 2) {
      this.viaLocation1Input.current.autocompleteInput.current.value =
        viaLocation2.description;
      this.viaLocation2Input.current.autocompleteInput.current.value =
        viaLocation1.description;
    } else if (this.state.itineraryCount === 3) {
      this.viaLocation1Input.current.autocompleteInput.current.value =
        viaLocation3.description;
      this.viaLocation3Input.current.autocompleteInput.current.value =
        viaLocation1.description;
    }

    // Update journey plan.
    const { journeyPlan } = this.state;
    journeyPlan.endLocation = endLocation;
    journeyPlan.startLocation = startLocation;

    var arrivalEvent = null;
    var departureEvent = null;

    if (journeyPlan.endLocation.eventId) {
      arrivalEvent = this.props.events.filter(
        (event) => event.id === journeyPlan.endLocation.eventId
      )[0];
    } else {
      this.props.setIsReturn(false);
    }

    if (journeyPlan.startLocation.eventId) {
      departureEvent = this.props.events.filter(
        (event) => event.id === journeyPlan.startLocation.eventId
      )[0];
    }

    if (arrivalEvent && departureEvent) {
      if (departureEvent.endTime != null) {
        this.departEventEnd(departureEvent, true);
      } else {
        this.arriveEventStart(arrivalEvent, true);
      }
    } else if (departureEvent) {
      this.departEventEnd(departureEvent, true);
    } else if (arrivalEvent) {
      this.arriveEventStart(arrivalEvent, true);
    }

    // Update.
    this.setState({ startLocation, endLocation }, () => {
      this.props.setLocationDropDownOpen(
        () => false,
        () => {
          const filters = this._getFiltersFromState();
          const publicTransportationOptions = this._getPublicOptionsFromState();
          if (
            this.state.searchOperationsFinished &
            !(
              endLocation.description === "" || startLocation.description === ""
            )
          ) {
            if (
              this.props.isReturn &&
              !this.state.journeyPlan?.endLocation?.endTime &&
              !this.state.journeyPlan.returnDepartureTime
            ) {
              // Force the user to set a returnDateTime.
              this.setState({ showOptionsModal: true });
              return;
            }

            this.props.postJourneyOptions(
              this.state.journeyPlan,
              filters,
              null,
              publicTransportationOptions,
              this.state.shouldUpdateReturnJourneys
            );
          }
        }
      );
    });
  };

  /**
   *
   * @param {Object} evt
   */
  useCurrentLocation = (evt, activeLocationTarget = undefined) => {
    if (activeLocationTarget === undefined) {
      activeLocationTarget = this.state.activeLocationTarget;
    }

    const setGeoLocation = (position) => {
      if (!position) {
        alert(
          "Location Services are disabled, please enable location services to use the Current Location."
        );
        return;
      }

      const location = {
        latitude: position.coords
          ? position.coords.latitude
          : position.latitude,
        longitude: position.coords
          ? position.coords.longitude
          : position.longitude,
        description: "Current Location",
      };

      //Make sure the marker on your current location is now displayed, since it's been requested, without changing the viewport
      this.props.showCurrentLocation(true);

      // console.log(`useCurrentLocation: ${activeLocationTarget}`, location);
      this.setState({ [activeLocationTarget]: location }, () => {
        this.updateResults();
      });
    };

    // Use value if already stored.
    const position = geolocationService.getCurrentPosition();
    if (position) {
      setGeoLocation(position);
      return;
    }

    // Update UI if user-instigated.
    if (evt) {
      this.setState({
        [activeLocationTarget]: { description: "Searching..." },
        showSuggestion: false,
      });
    }

    // Fetch geolocation.
    const processFailedGeoLocationFetch = (error) => {
      if (error.PERMISSION_DENIED === 1) {
        this.setState({
          [activeLocationTarget]: { description: "Enable location sharing" },
        });
      } else {
        this.setState({ [activeLocationTarget]: { description: "" } });
      }
    };

    geolocationService.fetchCurrentPosition(
      (position) => {
        // Success.
        if (!position) {
          processFailedGeoLocationFetch();
          return;
        }
        setGeoLocation(position);
      },
      (error) => {
        // Fail.
        console.log(error);
        processFailedGeoLocationFetch(error);
      }
    );
  };

  handleLocationChange = (locationTarget, description) => {
    if (Object.keys(description).length > 0) {
      this.setState({ isGoogleSuggestionActive: true });
    } else if (Object.keys(description).length === 0) {
      this.setState({ isGoogleSuggestionActive: false });
    }
    this.setState({
      [locationTarget]: { description },
      shouldUpdateReturnJourneys: true,
    });
  };

  handleTimeChange = (evt) => {
    const { activeTimeTarget, journeyPlan, time } = this.state;
    time.set({
      hour: evt.target.value.split(":")[0],
      minute: evt.target.value.split(":")[1],
    });
    journeyPlan[activeTimeTarget] = time.format();
    this.setState({
      time,
      journeyPlan,
      timeUpdated: true,
      shouldUpdateJourneys: true,
    });
    this.props.handleTimeChange(time);
  };

  handleReturnTimeChange = (evt) => {
    const { activeReturnTimeTarget, journeyPlan, returnTime } = this.state;

    let updatedReturnTime = returnTime
      ? returnTime.clone()
      : moment().locale(this.props.lang);

    updatedReturnTime.set({
      hour: evt.target.value.split(":")[0],
      minute: evt.target.value.split(":")[1],
    });

    const returnTimeTarget =
      activeReturnTimeTarget && activeReturnTimeTarget == "departureTime"
        ? "returnDepartureTime"
        : "returnArrivalTime";

    journeyPlan[returnTimeTarget] = updatedReturnTime.format();
    this.setState({
      returnTime,
      journeyPlan,
      timeUpdated: true,
      shouldUpdateReturnJourneys: true,
    });

    this.props.setShowReturn(false);
    this.props.handleReturnTimeChange(updatedReturnTime);
  };

  updateItineraryActive = (itineraryCount) => {
    this.props.handleItineraryActive(itineraryCount !== 0);
  };

  itineraryChange = () => {
    const itineraryCount = this.state.itineraryCount + 1;
    this.setState({ itineraryCount });

    this.props.setIsReturn(false);

    this.updateItineraryActive(itineraryCount);

    const viaLocationsHeight =
      itineraryCount > 0 ? `${55 * itineraryCount}px` : "0px";
    document.documentElement.style.setProperty(
      "--journey-locations-height",
      viaLocationsHeight
    );

    // switch (itineraryCount) {
    //   case 1:
    //     this.endLocationInput.current.autocompleteInput.current.value = "";
    //     break;
    //   case 2:
    //     this.viaLocation1Input.current.autocompleteInput.current.value = "";
    //     break;
    //   case 3:
    //     this.viaLocation2Input.current.autocompleteInput.current.value = "";
    //     break;
    // }
  };

  itineraryRemove = (evt) => {
    let { itineraryCount } = this.state;
    if (itineraryCount !== 3) {
      itineraryCount -= 1;
      this.setState({ itineraryCount });
    }
    this.updateItineraryActive(itineraryCount);

    const viaLocationsHeight =
      itineraryCount > 0 ? `${55 * itineraryCount}px` : "0px";
    document.documentElement.style.setProperty(
      "--journey-locations-height",
      viaLocationsHeight
    );

    switch (itineraryCount) {
      case 0:
        this.setState(
          {
            viaLocation1: {
              description: "",
              latitude: null,
              longitude: null,
              id: "",
              groupName: "",
            },
          },
          () => {
            this.updateResults();
          }
        );
        this.viaLocation1Input.current.autocompleteInput.current.value = "";
        break;
      case 1:
        this.setState(
          {
            viaLocation2: {
              description: "",
              latitude: null,
              longitude: null,
              id: "",
              groupName: "",
            },
          },
          () => {
            this.updateResults();
          }
        );
        this.viaLocation2Input.current.autocompleteInput.current.value = "";
        break;
      case 3:
        itineraryCount -= 1;
        this.setState(
          {
            itineraryCount,
            viaLocation3: {
              description: "",
              latitude: null,
              longitude: null,
              id: "",
              groupName: "",
            },
          },
          () => {
            this.updateResults();
          }
        );
        this.viaLocation3Input.current.autocompleteInput.current.value = "";
        break;
    }
  };

  onDatePickerDateChange = (date) => {
    const { activeTimeTarget, journeyPlan, time } = this.state;

    if (date !== null) {
      const newTime = time.clone();
      newTime.set({
        date: date.date(),
        month: date.month(),
        year: date.year(),
      });

      const updatedJourneyPlan = { ...journeyPlan };
      updatedJourneyPlan[activeTimeTarget] = newTime.format();

      this.setState({
        time: newTime,
        journeyPlan: updatedJourneyPlan,
        timeUpdated: true,
        shouldUpdateJourneys: true,
      });

      this.props.handleTimeChange(newTime);
    }
  };

  onReturnDatePickerDateChange = (date) => {
    const { activeReturnTimeTarget, journeyPlan, returnTime } = this.state;
    let updatedReturnTime = returnTime
      ? returnTime.clone()
      : moment().locale(this.props.lang);

    if (date !== null) {
      updatedReturnTime = moment(date).set({
        date: date.date(),
        month: date.month(),
        year: date.year(),
      });
    }

    const returnTimeTarget =
      activeReturnTimeTarget && activeReturnTimeTarget == "departureTime"
        ? "returnDepartureTime"
        : "returnArrivalTime";

    journeyPlan[returnTimeTarget] = updatedReturnTime.format();

    this.setState({
      returnTime: updatedReturnTime,
      journeyPlan,
      shouldUpdateReturnJourneys: true,
    });

    this.props.setShowReturn(false);
    this.props.handleReturnTimeChange(updatedReturnTime);
  };

  onSubmit = (evt) => {
    evt.preventDefault();
    // this.handleSubmit();
  };

  updateResults = () => {
    if (this.state.timeUpdated) {
      this.props.handleUpdateMarkerOptions();
    }

    if (
      this.state.startLocation.description === "" ||
      this.state.startLocation.description === undefined ||
      this.state.endLocation.description === "" ||
      this.state.endLocation.description === undefined
    ) {
      this.setState({ showOptionsModal: false });
      return;
    }

    if (!this.state.shouldUpdateJourneys && this.props.journeyPlanID) {
      this.setState({ showOptionsModal: false });
      return;
    }

    this.setState({ shouldUpdateJourneys: false });

    this.props.setCheckForUpdates(false);

    // Defocus input element.
    try {
      document.activeElement.blur();
    } catch (e) {
      // Handle exception in IE11.
      document.body.blur();
    }

    const {
      startLocation,
      endLocation,
      viaLocation1,
      viaLocation2,
      viaLocation3,
      itineraryCount,
    } = this.state;

    const data = {
      startLocation,
      endLocation,
      viaLocation1,
      viaLocation2,
      viaLocation3,
      itineraryCount,
    };

    // Compose journey plan.
    var journeyPlan = JSON.parse(JSON.stringify(this.state.journeyPlan));
    var activeTimeTarget = JSON.parse(
      JSON.stringify(this.state.activeTimeTarget)
    );

    const orderedLocations = [
      startLocation,
      endLocation,
      viaLocation1,
      viaLocation2,
      viaLocation3,
    ].filter((location) => !!location?.description);

    journeyPlan.startLocation = orderedLocations[0];
    journeyPlan.endLocation = orderedLocations[orderedLocations.length - 1];
    journeyPlan.viaLocations = orderedLocations.slice(
      1,
      orderedLocations.length - 1
    );

    if (
      journeyPlan.arrivalTime === null &&
      journeyPlan.departureTime === null
    ) {
      activeTimeTarget = "departureTime";
    }

    // Update.
    this.setState(
      {
        ...data,
        searchOperationsFinished: true,
        showOptionsModal: false,
        showSuggestion: false,
        activeTimeTarget,
      },
      () => {
        this.props.setLocationDropDownOpen(
          () => false,
          () => {
            const filters = this._getFiltersFromState();
            const publicTransportationOptions =
              this._getPublicOptionsFromState();

            this.props.setShowReturn(false);

            this.props.postJourneyOptions(
              journeyPlan,
              filters,
              this.state.timeUpdated,
              publicTransportationOptions,
              this.state.shouldUpdateReturnJourneys
            );
          }
        );
      }
    );
  };

  handlePlaceChanged = (locations) => {
    this.setState({ predictions: locations });
  };

  toggleArrive = () => {
    const { journeyPlan } = this.state;
    journeyPlan.arrivalTime = this.state.time.format();
    journeyPlan.departureTime = null;
    this.setState({
      journeyPlan,
      activeTimeTarget: "arrivalTime",
      timeUpdated: true,
      shouldUpdateJourneys: true,
    });
  };

  toggleDepart = () => {
    const { journeyPlan } = this.state;
    journeyPlan.departureTime = this.state.time.format();
    journeyPlan.arrivalTime = null;
    this.setState({
      journeyPlan,
      activeTimeTarget: "departureTime",
      timeUpdated: true,
      shouldUpdateJourneys: true,
    });
  };

  toggleReturnArrive = () => {
    const { journeyPlan, returnTime } = this.state;

    let updatedReturnTime = returnTime || moment().locale(this.props.lang);

    if (returnTime == null) {
      this.setState({ returnTime: moment().locale(this.props.lang) });
    }

    journeyPlan.returnArrivalTime = updatedReturnTime.format();
    journeyPlan.returnDepartureTime = null;
    this.setState({
      journeyPlan,
      activeReturnTimeTarget: "arrivalTime",
      shouldUpdateReturnJourneys: true,
    });
  };

  toggleReturnDepart = () => {
    const { journeyPlan, returnTime } = this.state;

    let updatedArrivalTime = returnTime || moment().locale(this.props.lang);

    if (returnTime == null) {
      this.setState({ returnTime: moment().locale(this.props.lang) });
    }

    journeyPlan.returnDepartureTime = updatedArrivalTime.format();
    journeyPlan.returnArrivalTime = null;
    this.setState({
      journeyPlan,
      activeReturnTimeTarget: "departureTime",
      shouldUpdateReturnJourneys: true,
    });
  };

  toggleClass = () => {
    this.props.setLocationDropDownOpen((prev) => !prev);
  };

  toggleOptionsModal = () => {
    if (this.props.showMarkerModal) {
      this.props.toggleMarkerModal();
    }

    const newJourneyPlan = {
      departureTime: this.state.journeyPlan.departureTime,
      arrivalTime: this.state.journeyPlan.arrivalTime,
      returnDepartureTime: this.state.journeyPlan.returnDepartureTime,
      returnArrivalTime: this.state.journeyPlan.returnArrivalTime,
    };

    if (
      !this.state.journeyPlan.departureTime &&
      !this.state.journeyPlan.arrivalTime
    ) {
      newJourneyPlan.departureTime = moment.parseZone().format();
    }

    if (
      !this.state.journeyPlan.returnDepartureTime &&
      !this.state.journeyPlan.returnArrivalTime
    ) {
      newJourneyPlan.returnDepartureTime = moment.parseZone().format();
    }

    const { showOptionsModal } = this.state;
    this.setState({
      showOptionsModal: !showOptionsModal,
      journeyPlan: newJourneyPlan,
    });
  };

  toggleSelection = () => {
    this.props.toggleSelection();
  };

  handleButtonClick = () => {
    this.props.isCheckForUpdate ? this.updateResults() : this.toggleSelection();
  };

  showProfile = () => {
    if (this.props.editProfile === true) {
      this.props.toggleEditProfile();
    }
    this.close();
    this.props.showProfile();
  };

  login = () => {
    this.props.handleLoginOperation();
  };

  handleAdultFilterQuantity = (increment) => {
    let { filterAdults } = this.state;
    if (
      (filterAdults > 0 && increment < 0) ||
      (filterAdults < 100 && increment > 0)
    )
      this.setState({
        filterAdults: filterAdults + increment,
        shouldUpdateJourneys: true,
      });
  };

  handleChildFilterQuantity = (increment) => {
    let { filterChildren } = this.state;
    if (
      (filterChildren > 0 && increment < 0) ||
      (filterChildren < 100 && increment > 0)
    )
      this.setState({
        filterChildren: filterChildren + increment,
        shouldUpdateJourneys: true,
      });
  };

  handleChademoFilter = () => {
    const currentFilter = this.state.filterChademo;
    this.setState({
      filterChademo: !currentFilter,
      shouldUpdateJourneys: true,
    });
    console.log(this.state);
  };

  handleComboCCSFilter = () => {
    const currentFilter = this.state.filterComboCCS;
    this.setState({
      filterComboCCS: !currentFilter,
      shouldUpdateJourneys: true,
    });
  };

  handleRapidAC43Filter = () => {
    const currentFilter = this.state.filterRapidAC43;
    this.setState({
      filterRapidAC43: !currentFilter,
      shouldUpdateJourneys: true,
    });
  };

  handleBusFilter = () => {
    const currentFilter = this.state.filterBus;
    this.setState({ filterBus: !currentFilter, shouldUpdateJourneys: true });
  };

  handleTramFilter = () => {
    const currentFilter = this.state.filterTram;
    this.setState({ filterTram: !currentFilter, shouldUpdateJourneys: true });
  };

  handleTrainFilter = () => {
    const currentFilter = this.state.filterTrain;
    this.setState({ filterTrain: !currentFilter, shouldUpdateJourneys: true });
  };

  /**
   *
   * @param {number} increment
   */
  handleRemainingRangeFilterQuantity = (increment) => {
    let { filterRemainingRange } = this.state;
    if (
      (filterRemainingRange > 0 && increment < 0) ||
      (filterRemainingRange < 1000 && increment > 0)
    )
      this.setState({
        filterRemainingRange: filterRemainingRange + increment,
        shouldUpdateJourneys: true,
      });
  };

  /**
   *
   * @param {number} increment
   */
  handleMaximumRangeFilterQuantity = (increment) => {
    let { filterMaximumRange } = this.state;
    if (
      (filterMaximumRange > 0 && increment < 0) ||
      (filterMaximumRange < 1000 && increment > 0)
    )
      this.setState({
        filterMaximumRange: filterMaximumRange + increment,
        shouldUpdateJourneys: true,
      });
  };

  /**
   *
   * @param {number} increment
   */
  handleChargingTimeFilterQuantity = (increment) => {
    let { filterChargingTime } = this.state;
    if (
      (filterChargingTime > 0 && increment < 0) ||
      (filterChargingTime < 1000 && increment > 0)
    )
      this.setState({
        filterChargingTime: filterChargingTime + increment,
        shouldUpdateJourneys: true,
      });
  };

  /**
   *
   * @param {string} filterName
   */
  handleFilter = (filterName) => {
    const value = this.state[filterName];
    this.setState({ [filterName]: !value, shouldUpdateJourneys: true });
  };

  close = () => {
    this.props.toggleSharepage(true);
  };

  updateDimensions = () => {
    this.setState({ width: window.innerWidth });
  };

  render() {
    const {
      activeTimeTarget,
      journeyPlan,
      showOptionsModal,
      showSuggestion,
      time,
      returnTime,
      activeReturnTimeTarget,
      predictions,
    } = this.state;

    const {
      startLocation,
      endLocation,
      viaLocation1,
      viaLocation2,
      viaLocation3,
      itineraryCount,
      width,
      activeLocationTarget,
    } = this.state;

    const {
      locationDropDownOpen,
      destinations,
      showButton,
      showForm,
      showMenu,
      isEvDestinationGroup,
      isPTOptions,
      showReturn,
    } = this.props;

    var filterText = "";

    if (activeLocationTarget !== undefined) {
      if (
        activeLocationTarget === "startLocation" &&
        this.startLocationInput !== null &&
        this.startLocationInput.current !== null
      ) {
        filterText =
          this.startLocationInput.current.autocompleteInput.current.value.toLowerCase();
      } else if (
        activeLocationTarget === "endLocation" &&
        this.endLocationInput !== null &&
        this.endLocationInput.current !== null
      ) {
        filterText =
          this.endLocationInput.current.autocompleteInput.current.value.toLowerCase();
      } else if (
        activeLocationTarget === "viaLocation1" &&
        this.viaLocation1Input !== null &&
        this.viaLocation1Input.current !== null
      ) {
        filterText =
          this.viaLocation1Input.current.autocompleteInput.current.value.toLowerCase();
      } else if (
        activeLocationTarget === "viaLocation2" &&
        this.viaLocation2Input !== null &&
        this.viaLocation2Input.current !== null
      ) {
        filterText =
          this.viaLocation2Input.current.autocompleteInput.current.value.toLowerCase();
      } else if (
        activeLocationTarget === "viaLocation3" &&
        this.viaLocation3Input !== null &&
        this.viaLocation3Input.current !== null
      ) {
        filterText =
          this.viaLocation3Input.current.autocompleteInput.current.value.toLowerCase();
      }
    }

    const pastJourneyPlan =
      this.state.time.isBefore(moment.parseZone(), "day") &&
      this.props.showTravelPlan;

    const disableAddItinerary =
      (itineraryCount === 1 && (!viaLocation1 || _.isEmpty(viaLocation1))) ||
      (itineraryCount === 2 && (!viaLocation2 || _.isEmpty(viaLocation2))) ||
      (itineraryCount === 3 && (!viaLocation3 || _.isEmpty(viaLocation3)));

    const returnTimeToUse = !returnTime
      ? moment.parseZone().locale(this.props.lang)
      : returnTime;

    return (
      <ThemeContext.Consumer>
        {(theme) => (
          <>
            <div className={styles["Header"]}>
              <div>
                {this.props.organisation?.isHeaderEnabled ? (
                  <Header
                    organisation={this.props.organisation}
                    showMenu={showMenu}
                  />
                ) : (
                  ""
                )}
              </div>
              <div className={styles["header-outer-container"]}>
                {
                  <div
                    className={styles["filter"]}
                    style={{
                      display: this.props.hideButtons ? "none" : "inline-block",
                    }}
                  >
                    <div className={styles["filter-scrollbox"]}>
                      <div className={styles["left-navbar-buttons"]}>
                        <div className={styles["filter-button-container"]}>
                          <button
                            data-cy={"dateFilterButton"}
                            style={{
                              color: theme.theme.primaryColour,
                              borderColor: theme.theme.primaryColour,
                            }}
                            className={styles["filter-button"]}
                            onClick={() => {
                              this.toggleOptionsModal();
                            }}
                          >
                            <span className={styles["cancel-button-span"]}>
                              {showReturn ? (
                                <>
                                  {returnTimeToUse
                                    .locale(this.props.lang)
                                    .format("Do MMM")}
                                </>
                              ) : (
                                <>
                                  {time
                                    .locale(this.props.lang)
                                    .format("Do MMM")}
                                </>
                              )}
                            </span>
                          </button>
                        </div>
                        <div className={styles["filter-button-container"]}>
                          <button
                            data-cy={"departureFilterButton"}
                            style={{
                              color: theme.theme.primaryColour,
                              borderColor: theme.theme.primaryColour,
                            }}
                            className={styles["filter-button"]}
                            onClick={() => {
                              this.toggleOptionsModal();
                            }}
                          >
                            <span className={styles["cancel-button-span"]}>
                              {showReturn ? (
                                activeReturnTimeTarget === "departureTime" ? (
                                  <>
                                    <FormattedMessage id="dep" />
                                    {" " +
                                      returnTimeToUse
                                        .locale(this.props.lang)
                                        .format("HH:mm")}
                                  </>
                                ) : (
                                  <>
                                    <FormattedMessage id="arr" />
                                    {" " +
                                      returnTimeToUse
                                        .locale(this.props.lang)
                                        .format("HH:mm")}
                                  </>
                                )
                              ) : activeTimeTarget === "departureTime" ? (
                                <>
                                  <FormattedMessage id="dep" />
                                  {" " +
                                    time
                                      .locale(this.props.lang)
                                      .format("HH:mm")}
                                </>
                              ) : (
                                <>
                                  <FormattedMessage id="arr" />
                                  {" " +
                                    time
                                      .locale(this.props.lang)
                                      .format("HH:mm")}
                                </>
                              )}
                            </span>
                          </button>
                        </div>
                        <div className={styles["filter-button-container"]}>
                          <div className={styles["hide-for-small"]}>
                            <button
                              style={{
                                color: theme.theme.primaryColour,
                                borderColor: theme.theme.primaryColour,
                              }}
                              className={styles["filter-button"]}
                              onClick={() => {
                                this.toggleOptionsModal();
                              }}
                            >
                              <span className={styles["cancel-button-span"]}>
                                <FormattedMessage id="options" />
                              </span>
                            </button>
                          </div>
                        </div>
                        <div
                          className={styles["filter-button-container"]}
                          style={{
                            display: "none",
                            // hidden as a business decision
                          }}
                        >
                          <div className={styles["hide-for-small"]}>
                            <button
                              className={styles["return-button"]}
                              style={{
                                color: this.props.isReturn
                                  ? "white"
                                  : theme.theme.primaryColour,
                                backgroundColor: this.props.isReturn
                                  ? theme.theme.primaryColour
                                  : "white",
                                borderColor: this.props.isReturn
                                  ? theme.theme.primaryColour
                                  : "white",
                              }}
                              onClick={() => {
                                this.toggleOptionsModal();
                              }}
                            >
                              <span className={styles["cancel-button-span"]}>
                                Return
                              </span>
                            </button>
                          </div>
                        </div>
                      </div>
                      {this.props.organisation?.isHeaderEnabled && !isMobile ? (
                        <div
                          style={{
                            marginTop: "-3px",
                            flexGrow: 1,
                          }}
                        >
                          <img
                            height={37}
                            width={37}
                            style={{
                              display: "block",
                              marginLeft: "auto",
                              marginRight: "auto",
                            }}
                            src={
                              this.props.organisation?.desktopHeader?.imageUrl
                            }
                            alt="new"
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      <div
                        style={{
                          width: "40px",
                          paddingRight: "5px",
                        }}
                        className={`${styles["filter-button-container"]} ${styles["right-menu-button"]}`}
                      >
                        {!theme?.hideClientElements?.includes(
                          "hideTravelProfile"
                        ) ? (
                          isMobile ? (
                            !this.props.organisation?.isHeaderEnabled ? (
                              <div
                                className={styles["menu-button-container"]}
                                onClick={showMenu}
                              >
                                <img
                                  src={menuImg}
                                  className={styles["menu-button"]}
                                  alt="Menu icon opens travel profile"
                                />
                              </div>
                            ) : (
                              ""
                            )
                          ) : this.props.auth.isAuthenticated() === true ? (
                            <div
                              data-cy={"travelProfileButtonAuthenticated"}
                              role="button"
                              tabIndex="0"
                              aria-pressed="false"
                              className={styles["menu-link-text"]}
                              style={{
                                color: !this.state.isButtonHovered
                                  ? "#484848"
                                  : theme.theme.primaryColour,
                              }}
                              onMouseEnter={() => {
                                this.setState({ isButtonHovered: true });
                              }}
                              onMouseLeave={() => {
                                this.setState({ isButtonHovered: false });
                              }}
                              onClick={() => {
                                if (!this.props.showLoading) this.showProfile();
                              }}
                              onKeyPress={() => {
                                if (!this.props.showLoading) this.showProfile();
                              }}
                            >
                              <FormattedMessage id="myTravelProfile" />
                            </div>
                          ) : (
                            <div
                              data-cy={"travelProfileButton"}
                              role="button"
                              tabIndex="0"
                              aria-pressed="false"
                              className={styles["menu-link-text"]}
                              style={{
                                color: !this.state.isButtonHovered
                                  ? "#484848"
                                  : theme.theme.primaryColour,
                              }}
                              onMouseEnter={() => {
                                this.setState({ isButtonHovered: true });
                              }}
                              onMouseLeave={() => {
                                this.setState({ isButtonHovered: false });
                              }}
                              onClick={() => {
                                if (!this.props.showLoading) this.login();
                              }}
                              onKeyPress={() => {
                                if (!this.props.showLoading) this.login();
                              }}
                            >
                              <FormattedMessage id="myTravelProfile" />
                            </div>
                          )
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                }
                {/* {!showForm && !showButton && (
              <>
                <div className={styles["header-inner-container"]}>
                  <span tabIndex="0">Plan your trip</span>
                </div>

                <div className={`${styles["filter-button-container"]} ${styles["float-right"]}`}>
                  {width < 800 ?
                    <div className={styles["menu-button-container"]} onClick={showMenu}>
                      <img src={menuImg} className={styles["menu-button"]} alt="menu" />
                    </div>
                    : (
                      this.props.auth.isAuthenticated() === true ? (<div className={styles["menu-link-text"]} onClick={() => this.showProfile()} onKeyPress={() => this.showProfile()} tabIndex="0">
                        Travel Profile
                      </div>) : (<div className={styles["menu-link-text"]} onClick={() => this.login()} onKeyPress={() => this.login()} tabIndex="0">
                        Travel Profile
                      </div> ) )}
                </div>
              </>
            )} */}
              </div>
            </div>
            <div className={styles["header-seperator"]} />

            <div>
              <div
                className={[
                  !showForm && !showButton
                    ? styles["EditJourney1"]
                    : styles["EditJourney"],
                  itineraryCount === 1 ? styles["show-1-itinerary"] : "",
                  itineraryCount === 2 ? styles["show-2-itinerary"] : "",
                  itineraryCount === 3 ? styles["show-3-itinerary"] : "",
                ].join(" ")}
              >
                <div className={styles["search"]}>
                  <div className={styles["search-outer-container"]}>
                    <div
                      className={
                        locationDropDownOpen
                          ? styles["search-inner-container-active"]
                          : styles["search-inner-container-inactive"]
                      }
                    >
                      <form
                        action="#"
                        className={styles["form"]}
                        id="form"
                        name="form"
                        onSubmit={this.onSubmit}
                        noValidate
                      >
                        <div className={styles["form-input-inner-container"]}>
                          <div className={styles["form-input-inner-content"]}>
                            <label
                              className={styles["form-input-label"]}
                              htmlFor="startLocationInput"
                            >
                              <FormattedMessage id="from" />:{" "}
                            </label>
                            <AutoComplete
                              ref={this.startLocationInput}
                              apiToken={this.props.apiToken}
                              id="startLocationInput"
                              tabIndex="0"
                              name="startLocation"
                              arialabel={"Enter your start location"}
                              value={startLocation.description}
                              valuePlaceholder={startLocation.description}
                              disableGoogleSuggestionList={
                                this.state.disableGoogleSuggestionList
                              }
                              suggestionSource={this.props.suggestionSource}
                              suggestionApproximateLocation={
                                this.props.suggestionApproximateLocation
                              }
                              onBlur={this.handleLocationInputBlur}
                              onFocus={this.handleLocationInputFocus}
                              onClick={this.handleLocationInputClick}
                              onChange={(e) => {
                                this.handleLocationChange(
                                  "startLocation",
                                  e.target.value
                                );
                              }}
                              onPlaceChanged={this.handlePlaceChanged}
                              setPrediction={this.setPrediction}
                              autoCompleteParams={this.props.autoCompleteParams}
                              autoCompleteCustomPlaces={
                                this.props.autoCompleteCustomPlaces
                              }
                              destinationGroupId={this.props.destinationGroupId}
                            />
                          </div>
                        </div>
                        <div className={styles["seperator-button-container"]}>
                          <div className={styles["swap-button-container"]}>
                            <button
                              className={styles["swap-button"]}
                              type="button"
                              onClick={this.handleSwapClick}
                              aria-label="You can make a journey in an opposite direction by clicking this button"
                            >
                              <img
                                alt="swap icon"
                                src={swapImg}
                                className={styles["swap-icon"]}
                              />
                            </button>
                          </div>
                          <div className={styles["seperator"]} />
                          <div
                            id="viaSep1"
                            className={styles["viaSeperator1"]}
                          />
                        </div>
                        <div
                          className={
                            styles["form-input-inner-container-bottom"]
                          }
                        >
                          <div className={styles["form-input-inner-content"]}>
                            <label
                              htmlFor="endLocationInput"
                              className={styles["form-input-label"]}
                            >
                              <FormattedMessage id="to" />:{" "}
                            </label>
                            <AutoComplete
                              ref={this.endLocationInput}
                              apiToken={this.props.apiToken}
                              arialabel={"Enter your destination location"}
                              id="endLocationInput"
                              name="endLocation"
                              aria-label="Your destination location"
                              value={endLocation.description}
                              valuePlaceholder={endLocation.description}
                              disableGoogleSuggestionList={
                                this.state.disableGoogleSuggestionList
                              }
                              suggestionSource={this.props.suggestionSource}
                              suggestionApproximateLocation={
                                this.props.suggestionApproximateLocation
                              }
                              onBlur={this.handleLocationInputBlur}
                              onFocus={this.handleLocationInputFocus}
                              onClick={this.handleLocationInputClick}
                              onChange={(e) => {
                                this.handleLocationChange(
                                  "endLocation",
                                  e.target.value
                                );
                              }}
                              onPlaceChanged={this.handlePlaceChanged}
                              setPrediction={this.setPrediction}
                              autoCompleteParams={this.props.autoCompleteParams}
                              autoCompleteCustomPlaces={
                                this.props.autoCompleteCustomPlaces
                              }
                              destinationGroupId={this.props.destinationGroupId}
                            />
                          </div>
                          {showButton &&
                            itineraryCount !== 3 &&
                            this.props.isItineraryEnabled && (
                              <div className={styles["swap-button-container2"]}>
                                <button
                                  disabled={disableAddItinerary}
                                  className={styles["plusButtonClass"]}
                                  type="button"
                                  onClick={this.itineraryChange}
                                >
                                  <img
                                    alt="add new destinations to your journey plan"
                                    src={plusImg}
                                    className={styles["plus-button"]}
                                  />
                                </button>
                              </div>
                            )}
                        </div>
                        <div className={styles["viaLocation1"]}>
                          <div
                            className={
                              styles["form-input-inner-container-bottom"]
                            }
                          >
                            <div className={styles["form-input-inner-content"]}>
                              <label
                                htmlFor="viaLocation1Input"
                                className={styles["form-input-label"]}
                              >
                                To:{" "}
                              </label>
                              <AutoComplete
                                ref={this.viaLocation1Input}
                                apiToken={this.props.apiToken}
                                arialabel={
                                  "Enter your second destination location"
                                }
                                id="viaLocation1Input"
                                name="viaLocation1"
                                aria-label="Your destination location"
                                value={
                                  viaLocation1 !== undefined
                                    ? viaLocation1.description
                                    : null
                                }
                                valuePlaceholder={
                                  viaLocation1 !== undefined
                                    ? viaLocation1.description
                                    : null
                                }
                                disableGoogleSuggestionList={
                                  this.state.disableGoogleSuggestionList
                                }
                                suggestionSource={this.props.suggestionSource}
                                suggestionApproximateLocation={
                                  this.props.suggestionApproximateLocation
                                }
                                onBlur={this.handleLocationInputBlur}
                                onFocus={this.handleLocationInputFocus}
                                onClick={this.handleLocationInputClick}
                                onChange={(e) => {
                                  this.handleLocationChange(
                                    "viaLocation1",
                                    e.target.value
                                  );
                                }}
                                onPlaceChanged={this.handlePlaceChanged}
                                setPrediction={this.setPrediction}
                                autoCompleteParams={
                                  this.props.autoCompleteParams
                                }
                                autoCompleteCustomPlaces={
                                  this.props.autoCompleteCustomPlaces
                                }
                                destinationGroupId={
                                  this.props.destinationGroupId
                                }
                              />
                            </div>
                            <div
                              id="crossButton1"
                              className={styles["cross-button-container1"]}
                            >
                              {itineraryCount === 1 && (
                                <>
                                  {" "}
                                  <button
                                    className={styles["crossButtonClass"]}
                                    type="button"
                                    onClick={this.itineraryRemove}
                                  >
                                    ✕
                                  </button>
                                </>
                              )}
                            </div>
                            <div
                              id="viaSep2"
                              className={styles["viaSeperator2"]}
                            />
                          </div>
                        </div>
                        <div className={styles["viaLocation2"]}>
                          <div
                            className={
                              styles["form-input-inner-container-bottom"]
                            }
                          >
                            <div className={styles["form-input-inner-content"]}>
                              <label
                                htmlFor="viaLocation2Input"
                                className={styles["form-input-label"]}
                              >
                                To:{" "}
                              </label>
                              <AutoComplete
                                ref={this.viaLocation2Input}
                                apiToken={this.props.apiToken}
                                id="viaLocation2Input"
                                arialabel={
                                  "Enter your third destination location"
                                }
                                name="viaLocation2"
                                aria-label="Your destination location"
                                value={
                                  viaLocation2 !== undefined
                                    ? viaLocation2.description
                                    : null
                                }
                                valuePlaceholder={
                                  viaLocation2 !== undefined
                                    ? viaLocation2.description
                                    : null
                                }
                                disableGoogleSuggestionList={
                                  this.state.disableGoogleSuggestionList
                                }
                                suggestionSource={this.props.suggestionSource}
                                suggestionApproximateLocation={
                                  this.props.suggestionApproximateLocation
                                }
                                onBlur={this.handleLocationInputBlur}
                                onFocus={this.handleLocationInputFocus}
                                onClick={this.handleLocationInputClick}
                                onChange={(e) => {
                                  this.handleLocationChange(
                                    "viaLocation2",
                                    e.target.value
                                  );
                                }}
                                onPlaceChanged={this.handlePlaceChanged}
                                setPrediction={this.setPrediction}
                                autoCompleteParams={
                                  this.props.autoCompleteParams
                                }
                                autoCompleteCustomPlaces={
                                  this.props.autoCompleteCustomPlaces
                                }
                                destinationGroupId={
                                  this.props.destinationGroupId
                                }
                              />
                            </div>
                            <div
                              id="crossButton2"
                              className={styles["cross-button-container2"]}
                            >
                              {itineraryCount === 2 && (
                                <>
                                  {" "}
                                  <button
                                    className={styles["crossButtonClass"]}
                                    type="button"
                                    onClick={this.itineraryRemove}
                                  >
                                    ✕
                                  </button>
                                </>
                              )}
                            </div>
                            <div
                              id="viaSep3"
                              className={styles["viaSeperator3"]}
                            />
                          </div>
                        </div>
                        <div className={styles["viaLocation3"]}>
                          <div
                            className={
                              styles["form-input-inner-container-bottom"]
                            }
                          >
                            <div className={styles["form-input-inner-content"]}>
                              <label
                                htmlFor="viaLocation3Input"
                                className={styles["form-input-label"]}
                              >
                                To:{" "}
                              </label>
                              <AutoComplete
                                ref={this.viaLocation3Input}
                                apiToken={this.props.apiToken}
                                id="viaLocation3Input"
                                arialabel={
                                  "Enter your fourth destination location"
                                }
                                name="viaLocation3"
                                aria-label="Your destination location"
                                value={
                                  viaLocation3 !== undefined
                                    ? viaLocation3.description
                                    : null
                                }
                                valuePlaceholder={
                                  viaLocation3 !== undefined
                                    ? viaLocation3.description
                                    : null
                                }
                                disableGoogleSuggestionList={
                                  this.state.disableGoogleSuggestionList
                                }
                                suggestionSource={this.props.suggestionSource}
                                suggestionApproximateLocation={
                                  this.props.suggestionApproximateLocation
                                }
                                onBlur={this.handleLocationInputBlur}
                                onFocus={this.handleLocationInputFocus}
                                onClick={this.handleLocationInputClick}
                                onChange={(e) => {
                                  this.handleLocationChange(
                                    "viaLocation3",
                                    e.target.value
                                  );
                                }}
                                onPlaceChanged={this.handlePlaceChanged}
                                setPrediction={this.setPrediction}
                                autoCompleteParams={
                                  this.props.autoCompleteParams
                                }
                                autoCompleteCustomPlaces={
                                  this.props.autoCompleteCustomPlaces
                                }
                                destinationGroupId={
                                  this.props.destinationGroupId
                                }
                              />
                            </div>
                            <div
                              id="crossButton3"
                              className={styles["cross-button-container3"]}
                            >
                              {itineraryCount === 3 && (
                                <>
                                  {" "}
                                  <button
                                    className={styles["crossButtonClass"]}
                                    type="button"
                                    onClick={this.itineraryRemove}
                                  >
                                    ✕
                                  </button>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                        <button
                          type="submit"
                          form="form"
                          className={styles["submit-button"]}
                        />
                      </form>
                    </div>
                  </div>
                </div>
                {!theme?.hideClientElements?.includes("hideAlertButton")
                  ? showButton &&
                    !locationDropDownOpen && (
                      <>
                        {!this.props.isCheckForUpdate ? (
                          <FormattedMessage id="getAlerts">
                            {(text) => (
                              <Button
                                visuallyDisabled={
                                  this.props.alertsButtonDisabled
                                }
                                data-cy={"checkForUpdatesButton"}
                                className={styles["Button"]}
                                description={"Get alerts for this trip"}
                                text={
                                  this?.props?.journeyPlanBtntxt
                                    ? this.props.journeyPlanBtntxt
                                    : theme.getTravelForecastText === ""
                                    ? text
                                    : theme.getTravelForecastText
                                }
                                handleButtonClick={this.handleButtonClick}
                              />
                            )}
                          </FormattedMessage>
                        ) : (
                          <FormattedMessage id="checkUpdates">
                            {(text) => (
                              <Button
                                visuallyDisabled={
                                  this.props.alertsButtonDisabled ||
                                  pastJourneyPlan
                                }
                                disabled={pastJourneyPlan}
                                data-cy={"checkForUpdatesButton"}
                                className={styles["Button"]}
                                description={"Get alerts for this trip"}
                                text={
                                  theme.getTravelForecastText === ""
                                    ? text
                                    : theme.getTravelForecastText
                                }
                                handleButtonClick={this.handleButtonClick}
                              />
                            )}
                          </FormattedMessage>
                        )}
                        {pastJourneyPlan ? (
                          <div>
                            <span className={styles["warning-message"]}>
                              You are viewing a journey plan which is in the
                              past.
                            </span>
                          </div>
                        ) : (
                          ""
                        )}
                      </>
                    )
                  : ""}
                {showOptionsModal && (
                  <OptionsModal
                    journeyPlan={journeyPlan}
                    time={time}
                    returnTime={returnTimeToUse}
                    handleTimeChange={this.handleTimeChange}
                    handleReturnTimeChange={this.handleReturnTimeChange}
                    handleAdultFilterQuantity={this.handleAdultFilterQuantity}
                    handleChildFilterQuantity={this.handleChildFilterQuantity}
                    handleRemainingRangeFilterQuantity={
                      this.handleRemainingRangeFilterQuantity
                    }
                    handleMaximumRangeFilterQuantity={
                      this.handleMaximumRangeFilterQuantity
                    }
                    handleChargingTimeFilterQuantity={
                      this.handleChargingTimeFilterQuantity
                    }
                    isHeaderEnabled={this.props.organisation?.isHeaderEnabled}
                    lang={this.props.lang}
                    handleFilter={this.handleFilter}
                    handleChademoFilter={this.handleChademoFilter}
                    handleComboCCSFilter={this.handleComboCCSFilter}
                    handleRapidAC43Filter={this.handleRapidAC43Filter}
                    filterBus={this.state.filterBus}
                    filterTram={this.state.filterTram}
                    filterTrain={this.state.filterTrain}
                    handleBusFilter={this.handleBusFilter}
                    handleTrainFilter={this.handleTrainFilter}
                    handleTramFilter={this.handleTramFilter}
                    onDatePickerDateChange={this.onDatePickerDateChange}
                    onReturnDatePickerDateChange={
                      this.onReturnDatePickerDateChange
                    }
                    state={this.state}
                    toggleArrive={this.toggleArrive}
                    toggleDepart={this.toggleDepart}
                    toggleReturnArrive={this.toggleReturnArrive}
                    toggleReturnDepart={this.toggleReturnDepart}
                    toggleModal={this.toggleOptionsModal}
                    updateResults={this.updateResults}
                    isEvDestinationGroup={isEvDestinationGroup}
                    isPTOptions={isPTOptions}
                    isReturn={this.props.isReturn}
                    setIsReturn={this.props.setIsReturn}
                    isItineraryActive={this.props?.isItineraryActive}
                  />
                )}
                {this.props.showMarkerModal && (
                  <MarkerModal
                    markerOptions={this.props.markerOptions}
                    markerOptionsAction={this.props.markerOptionsAction}
                    isHeaderEnabled={this.props.organisation?.isHeaderEnabled}
                    toggleModal={this.props.toggleMarkerModal}
                    isTrafficForecastAvailable={
                      this.props.isTrafficForecastAvailable
                    }
                    trafficForecast={this.props.trafficForecast}
                    switchTrafficForecast={this.props.switchTrafficForecast}
                  />
                )}
                {this.props.showDirectionsModal && (
                  <DirectionsModal
                    leg={
                      showReturn
                        ? this.props.selectedReturnLeg
                        : this.props.selectedLeg
                    }
                    isHeaderEnabled={this.props.organisation?.isHeaderEnabled}
                    toggleModal={this.props.toggleDirectionsModal}
                  />
                )}
                {locationDropDownOpen && showSuggestion && (
                  <div>
                    <div
                      role="listbox"
                      className={
                        !theme?.hideClientElements?.includes("hideFooter")
                          ? styles["listbox"]
                          : styles["listboxNoFooter"]
                      }
                    >
                      <ul className={styles["listbox-ul"]}>
                        <li className={styles["listbox-li"]}>
                          <div className={styles["li-title-outer-container"]}>
                            <div className={styles["li-title-inner-container"]}>
                              <span className={styles["li-title"]}>
                                <FormattedMessage id="suggestions" />
                              </span>
                              <button
                                type="button"
                                className={styles["listbox-close-button"]}
                                onClick={() => {
                                  this.props.setLocationDropDownOpen(
                                    () => false
                                  );
                                  this.setState({
                                    showSuggestion: false,
                                  });
                                }}
                                onKeyPress={() => {
                                  this.props.setLocationDropDownOpen(
                                    () => false
                                  );
                                  this.setState({
                                    showSuggestion: false,
                                  });
                                }}
                              >
                                <Close className={styles["Close"]} />
                              </button>
                            </div>
                          </div>
                          <ul className={styles["listbox-suggestion-ul"]}>
                            <li
                              tabIndex="-1"
                              className={styles["listbox-suggestion-li"]}
                              onClick={this.useCurrentLocation}
                            >
                              <div
                                className={
                                  styles["listbox-suggestion-li-icon-container"]
                                }
                              >
                                <span
                                  className={
                                    styles["listbox-suggestion-li-icon"]
                                  }
                                >
                                  <Location className={styles["Location"]} />
                                </span>
                              </div>
                              <div
                                className={
                                  styles[
                                    "listbox-suggestion-li-title-container"
                                  ]
                                }
                              >
                                <div
                                  className={
                                    styles["listbox-suggestion-li-title"]
                                  }
                                >
                                  Current Location
                                </div>
                                <div
                                  className={
                                    styles["listbox-suggestion-li-subtitle"]
                                  }
                                >
                                  Anytime
                                </div>
                              </div>
                            </li>
                            {destinations !== null &&
                              this.props.suggestionSource === "google" &&
                              destinations
                                .filter(
                                  (destination) =>
                                    destination.isMainDestinationGroup &&
                                    (destination.name
                                      ?.toLowerCase()
                                      .includes(filterText) ||
                                      destination.description
                                        ?.toLowerCase()
                                        .includes(filterText))
                                )
                                .map((destination, index) => (
                                  <li
                                    key={index}
                                    tabIndex="0"
                                    className={styles["listbox-suggestion-li"]}
                                    onClick={(e) => {
                                      this.getEventLocation(
                                        destination.relatedId,
                                        destination.name,
                                        destination
                                      );
                                    }}
                                  >
                                    <div
                                      className={
                                        styles[
                                          "listbox-suggestion-li-icon-container"
                                        ]
                                      }
                                    >
                                      <span
                                        className={
                                          styles["listbox-suggestion-li-icon"]
                                        }
                                      >
                                        <LocationOn
                                          className={styles["LocationOn"]}
                                        />
                                      </span>
                                    </div>
                                    <div
                                      className={
                                        styles[
                                          "listbox-suggestion-li-title-container"
                                        ]
                                      }
                                    >
                                      <div
                                        className={
                                          styles["listbox-suggestion-li-title"]
                                        }
                                      >
                                        {destination.name}
                                      </div>
                                      <div
                                        className={
                                          styles[
                                            "listbox-suggestion-li-subtitle"
                                          ]
                                        }
                                      >
                                        {destination.description}
                                      </div>
                                    </div>
                                  </li>
                                ))}
                            {predictions !== null &&
                              predictions.map((prediction, index) => (
                                <li
                                  key={index}
                                  tabIndex="0"
                                  className={styles["listbox-suggestion-li"]}
                                  onClick={(e) => {
                                    this.setPrediction(prediction);
                                  }}
                                >
                                  <div
                                    className={
                                      styles[
                                        "listbox-suggestion-li-icon-container"
                                      ]
                                    }
                                  >
                                    <span
                                      className={
                                        styles["listbox-suggestion-li-icon"]
                                      }
                                    >
                                      <VehicleDisplay
                                        vehicleType={prediction.stopPointType}
                                      />
                                    </span>
                                  </div>
                                  <div
                                    className={
                                      styles[
                                        "listbox-suggestion-li-title-container"
                                      ]
                                    }
                                  >
                                    <div
                                      className={
                                        styles["listbox-suggestion-li-title"]
                                      }
                                    >
                                      {prediction.title}
                                    </div>
                                    <div
                                      className={
                                        styles["listbox-suggestion-li-subtitle"]
                                      }
                                    >
                                      {prediction.description}
                                    </div>
                                  </div>
                                </li>
                              ))}
                          </ul>
                        </li>
                      </ul>
                      <img
                        style={{
                          display:
                            predictions !== null &&
                            predictions.length > 0 &&
                            this.props.suggestionSource === "google"
                              ? "inline"
                              : "none",
                        }}
                        alt="powered by google"
                        src={googleImg}
                        className={styles["google-attribution"]}
                      />
                    </div>
                  </div>
                )}
                <div className={styles["buffer"]} />
              </div>
            </div>
          </>
        )}
      </ThemeContext.Consumer>
    );
  }
}

export default EditJourney;
